import React, { Component } from 'react';
import GridRow from '../../master-components/Grid/GridRow';
import GridCol from '../../master-components/Grid/GridCol';
import Input from '../../master-components/Input/Input';
import InputLabel from '../../master-components/InputLabel/InputLabel';
import Button from '../../master-components/Button/Button';
import DropDownListSelect from '../../master-components/DropDownList/DropDownListSelect';
import { spinner } from '../../master-components/Spinner/SpinnerActions';
import config from '../../config/config';
import MechantAPI from './MerchantAPI'

class MerchantEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            nickname: '',
            phone_number:'',
            address: '',
            city:  '',
            state:  '',
            postcode:  '',
            platformId:  '',
            arrayUSStatesAll: [],
            titleState: {label: '', value: ''},
            referred_partner_id:  '',
            allChannelPartners: [],
            nameChannelPartner: {label: '', value: ''},
        }
    }

    componentDidMount = () => {
        const arrayUSStatesAll = this.getUSStates();
        this.setState({
          arrayUSStatesAll:  arrayUSStatesAll,
        });

        this.getAllChannelPartners()
            .then((res => {
                this.getMerchantById();
            }));
    }

    setCurrentState = (dataState) => {
        const arrayStates = this.state.arrayUSStatesAll.find(item => item.value === dataState);
        if (arrayStates) {
            this.setState({
                titleState: {
                    label:arrayStates.label,
                    value:arrayStates.value,
                }
            });
        }
    }

    setCurrentChannelPartner = (id) => {
        const channelPartner = this.state.allChannelPartners.find(item => item.value === id);
        if (channelPartner) {
            this.setState({
                nameChannelPartner: {
                    label: channelPartner.label,
                    value: channelPartner.value,
                }
            });
        }
    }

    componentWillReceiveProps = (nextProps) => {
        const {objEditChildren} = nextProps;
        if (objEditChildren !== '') {
            this.setState({
                id: objEditChildren.id,
                nickname: objEditChildren.nickname,
                phone_number: objEditChildren.phoneNumber,
                address: objEditChildren.address,
                city: objEditChildren.city,
                state: objEditChildren.state,
                postcode: objEditChildren.postcode + '',
                platformId: objEditChildren.platformId,
                referred_partner_id: objEditChildren.referred_partner_id,
            });
        }
    }


    getMerchantById = () => {
        const { match } = this.props;
        const dispatch = this.props.dispatch;
        const  id  = parseInt(match.params.merchantId);
        dispatch(spinner(true));
        MechantAPI.getMerchantById(id)
            .then((res => {
                dispatch(spinner(false));
                const merchant = res.data.data;
                this.setState({
                    id: merchant.id,
                    nickname: merchant.nickname,
                    phone_number: merchant.phoneNumber,
                    address: merchant.address,
                    city: merchant.city,
                    state: merchant.state,
                    postcode: merchant.postcode + '',
                    platformId: merchant.platformId,
                    referred_partner_id: merchant.referredPartnerId,
                });
                this.setCurrentState(merchant.state);
                this.setCurrentChannelPartner(merchant.referredPartnerId);
            }));
    }

    changeHandler = (value, name) => {
        this.setState({
            [name]: value
        });
    }

    getUSStates = () => {
        const options = config.usStates.map((item) => {
            return {
                label: item.name,
                value: item.abbreviation
            }
        });
        return options;
    }

    selectState = (data) => {
        this.setState({
            titleState: {
                    label: data.label,
                    value: data.value,
            },
            state: data.value
        });
    };

    getAllChannelPartners = () => {
        // const dispatch = this.props.dispatch;
        // dispatch(spinner(true));
        return MechantAPI.getAllChannelPartners()
          .then((res => {
              // dispatch(spinner(false));
              const allChannelPartners = res.data.data.map((item) => {
                  return {
                      label: item.name,
                      value: item.id
                  }
              });
              this.setState({
                  allChannelPartners: [{label: 'Null', value: null}, ...allChannelPartners],
              });
          }))
          .catch(error => {
              // dispatch(spinner(false));
              if (error.status) {
                  let errorMessage = error.data.data.message;
                  alert(errorMessage);
              }
          });

    }

    selectChannelPartner = (data) => {
        this.setState({
            nameChannelPartner: {
              label: data.label,
              value: data.value,
            },
            referred_partner_id: data.value
        });
    };

    saveMechant = () => {
        const { id } = this.state;
        const dispatch = this.props.dispatch;
        const params = {
           ...this.state
        }

        const {nameInput, phoneInput, addressInput, cityInput, postCodeInput, state} = this.refs
        if (nameInput.isValid() && phoneInput.isValid() && addressInput.isValid() && cityInput.isValid() && postCodeInput.isValid() && state.isValid()) {
            dispatch(spinner(true));
            MechantAPI.updateMerchant(parseInt(id), params)
                .then(({data}) => {
                    dispatch(spinner(false));
                }).catch(error => {
                    dispatch(spinner(false));
                    if (error.status) {
                        let errorMessage = error.data.data.message;
                        alert(errorMessage);
                    }
                });
        }
    }

    dcnCreate = () => {
        const { id } = this.state;
        const dispatch = this.props.dispatch;

        dispatch(spinner(true));
        MechantAPI.dcnCreate(parseInt(id))
            .then(({data}) => {
                dispatch(spinner(false));
            }).catch(error => {
                dispatch(spinner(false));
                if (error.status) {
                    let errorMessage = error.data.data.message;
                    alert(errorMessage);
                }
            });
    }

    render() {
        const {id, nickname, phone_number, address, city, postcode, platformId, titleState, arrayUSStatesAll, nameChannelPartner, allChannelPartners} = this.state;
        return (
           <div className='container-fluid mt-3 ml-3'>
                <GridRow>
                    <GridCol className='col-4'>
                        <InputLabel className='merchant-detail-label'>Merchant Name</InputLabel>
                        <Input ref='nameInput' value={nickname} name='nickname' type='text' onChange={this.changeHandler} min={3} max={50} validate />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='col-4 mt-3'>
                        <InputLabel className='merchant-detail-label'>Phone</InputLabel>
                        <Input ref='phoneInput' value={phone_number} name='phone_number'   type='tel' onChange={this.changeHandler}  validate />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='col-4 mt-3'>
                        <InputLabel className='merchant-detail-label'>Street</InputLabel>
                        <Input  ref='addressInput' value={address} name='address' type='text' onChange={this.changeHandler} min={3} max={50} validate />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='col-4 mt-3'>
                        <InputLabel className='merchant-detail-label'>City</InputLabel>
                        <Input ref='cityInput'  value={city} name='city'  type='text' onChange={this.changeHandler} min={3} max={50}  validate />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='col-2  mt-3'>
                        <InputLabel className='merchant-detail-label'>State</InputLabel>
                        <DropDownListSelect ref='state' value={titleState} name='state' onChange={this.selectState} options={arrayUSStatesAll} />
                    </GridCol>
                    <GridCol className='col-2  mt-3'>
                        <InputLabel className='merchant-detail-label'>Zip Code</InputLabel>
                        <Input ref='postCodeInput' value={postcode}  name='postcode' type='zipCode' onChange={this.changeHandler}  min={3} max={50} validate />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='col-4 mt-3'>
                        <InputLabel className='merchant-detail-label'>Platform ID</InputLabel>
                        <Input ref='platformIdInput' value={platformId} name='platformId' type='text' onChange={this.changeHandler} />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='col-4 mt-3'>
                        <InputLabel className='merchant-detail-label'>Channel Partner</InputLabel>
                        <DropDownListSelect ref='channelPartner' value={nameChannelPartner} name='channelPartner' onChange={this.selectChannelPartner} options={allChannelPartners} />
                    </GridCol>
                </GridRow>
                <GridRow>
                    <GridCol className='col-2 mt-4'>
                        <Button onClick={this.saveMechant} className='w-100 btn-rb-green clickable' size='lg' text='Save' />
                    </GridCol>
                    { localStorage.getItem('isAnalyst') === '1' ?
                    <GridCol className='col-2 mt-4'>
                        <Button onClick={() => this.props.syncMechantLocations(id)} className='w-100 btn-rb-yellow clickable' size='lg' text='Sync Locations' />
                    </GridCol>
                    : null }
                    { localStorage.getItem('isAnalyst') === '1' ?
                    <GridCol className='col-2 mt-4'>
                        <Button onClick={() => this.props.syncMechantDistributors(id)} className='w-100 btn-rb-yellow clickable' size='lg' text='Sync Distributors' />
                    </GridCol>
                    : null }
                    { localStorage.getItem('isAnalyst') === '1' ?
                    <GridCol className='col-2 mt-4'>
                        <Button onClick={this.dcnCreate} className='w-100 btn-rb-yellow clickable' size='lg' text='Create Dcn' />
                    </GridCol>
                    : null }
                </GridRow>
            </div>
        );
    }
}

export default MerchantEdit;
