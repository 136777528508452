import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import InputLabel from '../../master-components/InputLabel/InputLabel';
import Button from '../../master-components/Button/Button';
// import CheckBoxNew from '../../master-components/CheckBoxNew/CheckBoxNew';
import GridRow from '../../master-components/Grid/GridRow';
// import GridCol from '../../master-components/Grid/GridCol';
// import Input from '../../master-components/Input/Input';
import LoginAPI from './LoginAPI';
import setToken from '../../config/tokenAPI'
import { spinner } from '../../master-components/Spinner/SpinnerActions';
import { withMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/msAuthConfig';

class LoginContent extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         email: '',
    //         password: '',
    //         message: ''
    //     }
    // }

    componentDidMount = () => {
        const msalInstance = this.props.msalContext.instance;
        msalInstance.handleRedirectPromise()
            .then(this.handleMsalResponse)
            .catch(err => {
                console.error(err);
            });
    };

    checkRemeberMe = () =>{

    }

    changeHandler = (value, name) => {
        this.setState({
            [name]: value
        });
    }

    goToLogin = (e) => {
        e.preventDefault();
        if (this.state.email === '' || this.state.password === '') {
            alert('Fields can not be empty');
        } else {
            const action = this.props.dispatch;
            action(spinner(true));
            LoginAPI.authenticateAdminPortal(this.state.email, this.state.password)
                .then(({ data })=> {
                    action(spinner(false));
                    let result = data.data;
                    localStorage.setItem('authKey', result.authKey);
                    localStorage.setItem('email', result.email);
                    localStorage.setItem('isAnalyst', result.isAnalyst);
                    setToken(result.authKey);
                    this.props.history.push('/dashboard');
                    }).catch(error => {
                        if (error) {
                            let errorMessage = error.data.data.message;
                            alert(errorMessage);
                        }
                        action(spinner(false));
                    });
        }
    }

    goToForgotPassword = (e) => {
        e.preventDefault();
        this.props.history.push('/forgot-password');
    }

    handleMsalResponse = (res) => {
        if (!res) return;
        const params = {
            accessToken: res.accessToken,
            tenantId: res.tenantId,
            tokenType: res.tokenType,
            name: res.account && res.account.name,
            email: res.account && res.account.username,
            localEmail: 'analyst@admin.com',
        };
        const action = this.props.dispatch;
        action(spinner(true));
        LoginAPI.authenticateMicrosoft(params)
            .then(({ data })=> {
                action(spinner(false));
                let result = data.data;
                localStorage.setItem('authKey', result.authKey);
                localStorage.setItem('email', result.email);
                localStorage.setItem('isAnalyst', result.isAnalyst);
                setToken(result.authKey);
                this.props.history.push('/dashboard');
                }).catch(error => {
                    if (error) {
                        let errorMessage = error.data.data.message;
                        alert(errorMessage);
                    }
                    action(spinner(false));
                });
    }

    login = () => {
        const msalInstance = this.props.msalContext.instance;
        msalInstance.loginRedirect(loginRequest).then(this.handleMsalResponse);
    }

    logout = () => {
        localStorage.clear();
        // removeToken();
        const instance = this.props.msalContext.instance;
        instance.logoutRedirect({
            account: instance.getAccountByHomeId(),
            postLogoutRedirectUri: '/',
            mainWindowRedirectUri: '/'
        });
        this.props.history.push('/');
    }

    render() {
        // const { email, password } = this.state;
        // const isAuthenticated = this.props.msalContext.accounts.length > 0;
        // console.log('this.props.msalContext', this.props.msalContext);
        const isAuthenticated = !!localStorage.getItem('authKey');
        return (
            <Fragment>
                <div className='login-form'>
                    <GridRow>
                        { isAuthenticated ?
                            <Button variant='blue' text='Logout' onClick={this.logout} />
                        :
                            <Button variant='blue' text='Login with Microsoft' onClick={this.login} />
                        }
                    </GridRow>
                </div>
            </Fragment>
        );
        // return (
        //     <Fragment>
        //         <form className='login-form' onSubmit={this.goToLogin}>
        //             <GridRow>
        //                 <GridCol className='text-center'>
        //                     <InputLabel className='login-title'>Login</InputLabel>
        //                 </GridCol>
        //             </GridRow>
        //             <GridRow>
        //                 <InputLabel className='login-text'>Email</InputLabel>
        //                 <Input value={email}  type='email' name='email' onChange={this.changeHandler} autocomplete='off' validate min={5} max={25} />
        //             </GridRow>
        //             <GridRow className='mt-4'>
        //                 <InputLabel className='login-text'>Password</InputLabel>
        //                 <Input value={password} name='password' onChange={this.changeHandler} type='password' autocomplete='off' validate min={5} max={25}  />
        //             </GridRow>
        //             <GridRow>
        //                <CheckBoxNew  name='checkRemember'  text='Remember Me' onChange={this.checkRemeberMe}/>
        //             </GridRow>
        //             <GridRow>
        //                 <Button type='submit' variant='blue' text='Login' />
        //             </GridRow>
        //             <GridRow className='mt-4'>
        //                 <GridCol className='text-center' onClick={this.goToForgotPassword}>
        //                     <InputLabel className='login-forgot-password clickable'>Forgot  Password</InputLabel>
        //                 </GridCol>
        //             </GridRow>
        //         </form>
        //     </Fragment>
        // );
    }
}

const mapStateToProps = state => {
    return {
        spinner: state.spinner.spinner
    };
}

export default connect(mapStateToProps)(withMsal(LoginContent));
